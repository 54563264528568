import React, { useEffect, useRef, useState } from "react";
import CustomInput from "../../component/semantic/Input";
import Typography from "../../component/semantic/Typography";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import { addReachThunk, loginThunk } from "../../store/thunk/Login.thunk";
import { NavLink, useNavigate } from "react-router-dom";
import { emptyNavigationUrl } from "../../store/slices/Login.slice";
import Header from "./components/Header.home.component";
import Navbar from "./components/Navbar.home.component";
import LoginBtn from "./components/LoginBtn.home";
import LoginPosterOne from "../../assets/icons/dashboard/loginPosterOne.svg";
import PartnerHeadingOneIcon from "../../assets/icons/dashboard/partnerHeadingOne.svg";
import PartnerHeadingOneIconTwo from "../../assets/icons/dashboard/partnerHeadingTwo.svg";
import ContactImg from "../../assets/icons/home/contact.svg";
import IdeaBulb from "../../assets/icons/home/idea-lightbulb.svg";
import { MdOutlineMailOutline, MdOutlinePhone } from "react-icons/md";
import ExistingPartner from "./components/ExistingPartner.component";
import PolifyxPartner from "./components/PolifyxPartner";
import AboutPartners from "./components/AboutPartners.component";
import VideosTestimonial from "./components/VideosTestimonial.component";
import PolifyxAppRef from "./components/PolifyxAppRef.component";
import Footer from "./components/Footer.component";
import { GoEye } from "react-icons/go";
import { useFormik } from "formik";
import { reachSchema } from "../../helpers/schemas/Simple.Schema";
import FormInput from "../../component/semantic/FormInput";
import ReactSelect from "react-select";
import { errorStyles, polyfixSelect, customMultiValue } from "../../helpers/constants/styles";
import { natureOfBusiness } from "../../store/thunk/Profile.thunk";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useAppDispatch();
  const [showPassword, setShowpassword] = useState(false);

  const route = useAppSelector((state) => state.rootReducer.login.navigationUrl);
  const navigate = useNavigate();

  if (route) {
    navigate(route);
    dispatch(emptyNavigationUrl());
  }
  const loginHandler = (e: any) => {
    e.preventDefault();
    const email = e.target[0].value;
    const password = e.target[1].value;
    if (!email || !password) return;
    dispatch(loginThunk({ email, password }));
  };

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue } = useFormik({
    initialValues: { email: "", name: "", phone: "", natOfBusi: "" },
    validationSchema: reachSchema,
    onSubmit: async (values: any) => {
      dispatch(addReachThunk({ ...values, natOfBusi: values.natOfBusi.name }))
        .unwrap()
        .then((data) => {
          if (data?.data?.status === 200) {
            toast.success(data?.data?.message);
            resetForm();
          } else if (data?.data?.status === 401) {
            toast.error(data?.data?.message);
          }
        });
    }
  });

  const natureOfBuisnessList = useAppSelector((state) => state.rootReducer.profile.natureOfBuisness.data);

  useEffect(() => {
    dispatch(natureOfBusiness());
  }, []);
  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  const polyfixRef: any = useRef(null);

  return (
    <React.Fragment>
      <Header />
      <Navbar />

      <div className="bg-[#f0f4fd] px-[4%] md:px-[8%]">
        <div className="w-full block md:flex justify-between">
          <div className="w-full md:w-[70%] py-[4%]">
            <div className="">
              <div className="flex md:block justify-between">
                <Typography className="text-xl mt-4 md:mt-0 md:text-3xl">Already a Partner, Login here!</Typography>
                <img alt="poster-1-bg" className="w-[50%] block md:hidden" src={LoginPosterOne} />
              </div>
            </div>
            <form onSubmit={loginHandler} className="grid grid-flow-row-dense grid-cols-1 md:grid-cols-2 gap-6 md:gap-10 mt-4">
              <div className="col-span-1">
                <span className="">
                  <CustomInput type="email" placeholder="Email ID*" />
                  <div className="flex justify-between px-0 mt-2">
                    <Typography className="text-[10px] md:text-sm">Enter registered email ID</Typography>
                    <NavLink to={"/auth/signup/"} className="text-[10px] md:text-sm font-bold underline">
                      Want to become a partner?
                    </NavLink>
                  </div>
                </span>
              </div>
              <div className="col-span-1 relative flex flex-col items-center">
                <div className="relative w-full">
                  <input type={showPassword ? "text" : "password"} className="appearance-none border focus:ring-2 focus:outline-none focus:ring-blue-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:shadow-outline pr-10" placeholder="Password *" />
                  <button type="button" onClick={() => setShowpassword(!showPassword)} className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <GoEye className="w-5 h-5 text-gray-500" />
                  </button>
                </div>
                <div className="flex justify-end w-full mt-2">
                  <NavLink to={"/auth/recoverPassword"} className="text-[10px] md:text-sm font-bold text-end underline">
                    Forgot Password
                  </NavLink>
                </div>
              </div>

              <div className="col-span-1">
                <LoginBtn className="py-2 font-bold">Login</LoginBtn>
              </div>
            </form>
          </div>

          <div className="relative ml-4 hidden md:block">
            <img alt="poster-1-bg" className="" src={LoginPosterOne} />
            {/* <img alt="poster-1" src={LoginPosterOne} className="w-[85%]" /> */}
          </div>
        </div>
      </div>

      <div className="bg-white py-[6%] md:py-[4%] px-[4%] md:px-[8%] block md:flex md:justify-between">
        <div className="w-full md:w-[40%]">
          <div className="w-[90%] mb-4">
            <Typography className="text-5xl">Be the Future of tomorrow!</Typography>
          </div>
          <Typography className="text-sm">Collaborating with Insurance Samadhan presents a valuable opportunity for insurance brokers, advisors, corporates, and fintech organizations. As our partner, you'll have access to a team of experts dedicated to resolving complex insurance issues and helping those affected by insurance grievances. Join us today and be a part of the solution.</Typography>
        </div>
        <div className="w-full md:w-[50%] mt-10 md:mt-0">
          <div className="flex justify-between flex-wrap">
            <div className="bg-[#DFEAE7] px-4 rounded-lg">
              <Typography className="text-2xl my-2" variant="h2">
                100+
              </Typography>
              <Typography className="text-sm my-2">Partnerships</Typography>
            </div>
            <div className="bg-[#F0F4FD] px-4 rounded-lg">
              <Typography className="text-2xl my-2" variant="h2">
                3,500+
              </Typography>
              <Typography className="text-sm my-2">Advisor Network</Typography>
            </div>
            <div className="bg-[#FEF2E5] px-4 rounded-lg hidden md:block">
              <Typography className="text-2xl my-2" variant="h2">
                100+
              </Typography>
              <Typography className="text-sm my-2">Hospital Complaints Resolved</Typography>
            </div>

            <div className="bg-[#FEF2E5] mt-4 w-full px-4 rounded-xl block md:hidden">
              <Typography className="text-2xl my-2" variant="h2">
                1000+
              </Typography>
              <Typography className="text-sm my-2" variant="h2">
                App Downloads
              </Typography>
            </div>
            <div className="mt-6">
              <Typography className="text-lg mb-4" variant="h2">
                Benefits of Partnership
              </Typography>
              <div className="flex">
                <div className="mr-2 mt-1">
                  <img alt="icon" src={PartnerHeadingOneIcon} className="w-[55px] md:w-[40px]" />
                </div>
                <Typography className="text-sm ">Become a partner and earn commissions on every successful case resolution. Use our app to track your earnings in real-time and access tools to help you succeed.</Typography>
              </div>
              <div className="flex items-center">
                <div className="mr-2 mt-1">
                  <img alt="icon" src={PartnerHeadingOneIconTwo} className="my-auto w-[36px] md:w-[28px]" />
                </div>
                <Typography className="text-sm ">Manage customer relationships by tracking interactions and managing communication.</Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#DFEAE7] px-[4%] md:px-[8%] py-[4%] relative">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-[50%]">
            <div className="w-full justify-center flex md:hidden">
              <div className="w-[80%]">
                <img alt="contact-img" src={ContactImg} />
              </div>
            </div>
            <Typography className="text-3xl md:text-5xl my-5 md:my-auto" variant="h2">
              Reach out to us
            </Typography>
            <Typography className="sm">We are thrilled to have you consider joining us as a partner. Don't wait any longer. Register with us today and start benefiting from this fantastic partnership opportunity.</Typography>
            <div className="w-[80%] justify-center hidden md:flex">
              <img alt="contact-img" onClick={() => (polyfixRef.current ? polyfixRef.current.scrollIntoView({ behavior: "smooth" }) : "")} src={ContactImg} />
            </div>
          </div>
          <div className="w-full md:w-[50%] gap-9">
            <div className="mt-4">
              <FormInput name="name" placeholder="Name*" formProps={formProps} />
            </div>

            <div className="mt-4">
              <FormInput name="email" placeholder="Email ID*" formProps={formProps} />
            </div>
            <div className="mt-4">
              <FormInput placeholder="Phone Number*" limit={10} name="phone" formProps={formProps} />
            </div>
            <div className="mt-4">
              <ReactSelect
                placeholder="Select Nature of business"
                styles={errors.natOfBusi && touched["natOfBusi"] ? errorStyles : { ...polyfixSelect, ...customMultiValue }}
                options={natureOfBuisnessList.map((obj: any) => {
                  return { name: obj };
                })}
                onChange={(selectedOptions: any) => {
                  setFieldValue("natOfBusi", selectedOptions);
                }}
                value={values.natOfBusi}
                menuPlacement="top"
                getOptionValue={(option: any) => option?.name}
                getOptionLabel={(option: any) => option?.name}
              />
              {errors.natOfBusi && touched["natOfBusi"] && <span className=" text-red-600 font-semibold text-sm mt-1">Please Select Nature of business</span>}{" "}
            </div>
            {/* <div className="mt-4">
              <CustomTextArea rows={4} placeholder="Anything else to say" />
            </div> */}
            <div className="mt-4 w-full flex justify-end">
              <button className="bg-black text-white px-10 text-sm rounded-3xl py-2" onClick={() => handleSubmit()}>
                Submit{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-center ">
          <div className="w-[92%] z-50 md:w-[34%] bg-[#304E3F] absolute  bottom-[-10] md:mt-0 mt-[50px] rounded-3xl px-8 py-8 text-center text-white block md:flex items-center flex-col md:flex-row gap-7 ">
            <div className="z-20 my-4">
              <img alt="idea-bulb" src={IdeaBulb} className="z-50" />
            </div>
            <div className="text-left">
              <Typography className="text-[1rem] mt-4 md:mt-0 md:text-[1.7rem] flex">Contact Lalit for more information!</Typography>
              <div className="block md:flex flex-wrap ">
                <a href="tel:9810524526" className="flex mt-3 md:mt-0">
                  <MdOutlinePhone className="text-lg my-auto" /> <Typography className="text-base my-auto whitespace-nowrap">&nbsp;98105-24526</Typography>
                </a>{" "}
                <div className="md:block flex ">&nbsp; | &nbsp;</div>{" "}
                <a href="mailto:lalit@insurancesamadhan.com" className="flex mt-2 md:mt-0 pl-0 2xl:pl-8">
                  <MdOutlineMailOutline className="text-lg my-auto" /> &nbsp; <Typography className="text-base	my-auto">lalit@insurancesamadhan.com</Typography>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ExistingPartner />
      <PolifyxPartner />
      <AboutPartners />
      <VideosTestimonial />
      <PolifyxAppRef polyfixRef={polyfixRef} />
      <Footer />
    </React.Fragment>
  );
};

export default Login;
