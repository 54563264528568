import dayjs from "dayjs";
import React from "react";
import { FaFile } from "react-icons/fa";
import Typography from "../../../component/semantic/Typography";
import { s3Url } from "../../../helpers/constants/defaultUrls";

interface TypographyProps {
  data?: any;
}

const PartnerDetails: React.FC<TypographyProps> = ({ data }) => {
  const details = data.data;
  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-6 gap-4">
        <div>
          <div className="font-bold text-gray-500">Total leads</div>
          <div className="font-bold">{details.totalLeads}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Leads Today</div>
          <div className="font-bold">{details.leadsToday}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Leads in this month</div>
          <div className="font-bold">{details.leadsLastMonth}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Leads in last 3 months</div>
          <div className="font-bold">{details.leadsLastQuarter}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Leads in last 6 months</div>
          <div className="font-bold">{details.leadsLastHalfYearly}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Leads in last 1 year</div>
          <div className="font-bold">{details.leadsLastYearly}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Associates in this Month</div>
          <div className="font-bold">{details.assocThisMonth}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Associates in last 3 Months</div>
          <div className="font-bold">{details.assocQuarterly}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Associates in last 6 Months</div>
          <div className="font-bold">{details.assocHalfYearly}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Total Associates</div>
          <div className="font-bold">{details.totalAssoc}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Advisors in this Month</div>
          <div className="font-bold">{details.advisorThisMonth}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Advisors in last 3 Months</div>
          <div className="font-bold">{details.advisorQuarterly}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Advisors in last 6 Months</div>
          <div className="font-bold">{details.advisorHalfYearly}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Total Advisors</div>
          <div className="font-bold">{details.totalAdvisor}</div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Total Value</div>
          <div className="font-bold">
            ₹ <span className="text-green-500">{details.claimAmount}</span>
          </div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Total Revenue Share Projected</div>
          <div className="font-bold">
            ₹ <span className="text-green-500">{details.totalPendingProjected}</span>
          </div>
        </div>
        <div>
          <div className="font-bold text-gray-500">Total Revenue Share Actual</div>
          <div className="font-bold">
            ₹ <span className="text-green-500">{details.totalPendingActual}</span>
          </div>
        </div>
        <div>
          <div className="font-bold text-gray-500">User Since</div>
          <div className="font-bold">{dayjs(details.userData.createdAt).format("DD MMM YYYY")}</div>
        </div>
        {/* <div>
        <div className="font-bold text-gray-500">Level</div>
        <div className="font-bold">{hierarchicalLevelHandler(details.hierarchicalLevel)}</div>
      </div> */}
      </div>
      <div className="mt-5">
        <Typography className="p-2 px-3 font-semibold  bg-[#407cff28] break-all bg rounded-md">Bank Details</Typography>
        <div className="flex flex-wrap flex-col md:flex-row justify-between mt-3 px-1">
          <div>
            <div className="font-bold text-gray-500">Bank Name</div>
            <div className="font-bold">{details.userData?.bank?.bankName}</div>
          </div>
          <div>
            <div className="font-bold text-gray-500">Branch</div>
            <div className="font-bold">{details.userData?.bank?.branch}</div>
          </div>
          <div>
            <div className="font-bold text-gray-500">Account Number</div>
            <div className="font-bold">{details.userData?.bank?.accountNumber}</div>
          </div>
          <div>
            <div className="font-bold text-gray-500">Cancelled Cheque</div>
            {details.userData?.cancelledCheque && (
              <div className="flex text-sm font-bold bg-uploadTheme gap-2 px-4 rounded-lg py-1 items-center">
                <FaFile className="text-gray-400 opacity-50" />
                <a href={s3Url + details.userData._id + "/" + details.userData?.cancelledCheque} target="_blanck" onClick={(e) => e.stopPropagation()}>
                  {details.userData?.cancelledCheque}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerDetails;
