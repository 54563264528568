import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axios";
import { baseUrl } from "../../helpers/constants/defaultUrls";

interface GetAllLeadArgs {
  id: string;
  pageSize: number;
  currentPage: number;
  status?: string;
}

interface searchLeadCustomerArgs {
  search: string;
  pageSize: number;
  currentPage: number;
  id: string;
  userType: string;
}

interface searchLeadPartnerArgs {
  search: string;
  pageSize: number;
  currentPage: number;
}

interface addCommentArgs {
  id: string;
  body: {
    comment: string;
    notificationsTo: any;
    private: boolean;
    type: string;
  };
}

interface AssignArgs {
  leadId: string;
  userId: string;
}

export const getAllLeadThunk = createAsyncThunk("lead/getAllLeadThunk", async (payload: GetAllLeadArgs) => {
  try {
    const response = await axios.get(baseUrl + `v2/lead/getpartnerleadsv2?page=${payload.currentPage - 1}&size=${payload.pageSize}&userId=${payload.id}${payload.status ? "&status=" + payload.status : ""}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const searchLeadCustomerThunk = createAsyncThunk("lead/searchLeadCustomerThunk", async (payload: searchLeadCustomerArgs, { rejectWithValue }) => {
  try {
    const response = await axios.get(baseUrl + `v2/lead/searchLeads?search=${payload.search}&page=${payload.currentPage - 1}&size=${payload.pageSize}&userAccount=${payload.id}&userType=${payload.userType}`);
    return response;
  } catch (err: any) {
    if (err.response && err.response.data) {
      return rejectWithValue(err.response.data);
    }
    return rejectWithValue(err.message);
  }
});

export const searchLeadPartnerThunk = createAsyncThunk("lead/searchLeadPartnerThunk", async (payload: searchLeadPartnerArgs) => {
  const response = await axios.get(baseUrl + `v2/lead/searchLeadsAAP?search=${payload.search}&page=${payload.currentPage - 1}&size=${payload.pageSize}`);
  return response;
});

export const leadCommentHistoryThunk = createAsyncThunk("lead/leadCommentHistoryThunk", async (id: string) => {
  const response = await axios.get(baseUrl + `v1/lead/comment/get?leadId=` + id);
  return response;
});

export const addLeadCommentThunk = createAsyncThunk("lead/addLeadCommentThunk", async (payload: addCommentArgs) => {
  const response = await axios.put(baseUrl + `v1/lead/comment/addV2/${payload.id}`, payload.body);
  return response;
});

export const getAllAgentsThunk = createAsyncThunk("lead/getAllAgentsThunk", async () => {
  const response = await axios.get(baseUrl + `v1/user/agents/all`);
  return response;
});

export const assignToExecutiveThunk = createAsyncThunk("lead/assignToExecutiveThunk", async (payload: AssignArgs) => {
  const response = await axios.put(baseUrl + `v1/lead/assign/userV2`, payload);
  return response;
});

export const assignToExpertThunk = createAsyncThunk("lead/assignToExpertThunk", async (payload: AssignArgs) => {
  const response = await axios.put(baseUrl + `v1/lead/assign/expertV2`, payload);
  return response;
});

export const changeStatusHandler = createAsyncThunk("lead/changeStatus", async (payload: any) => {
  const response = await axios.put(baseUrl + `v1/lead/status/change`, payload);
  return response;
});

export const getSingleLeadInfo = createAsyncThunk("lead/changeStatus", async (id: string) => {
  const response = await axios.get(baseUrl + `v1/lead/single/` + id);
  return response;
});
