import { createSlice } from "@reduxjs/toolkit";
import { createCaseThunk, downloadLeadDocThunk } from "../thunk/NewCase.thunk";
import { toast } from "react-toastify";
interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  caseCreate: responseData;
  downloadDoc: any;
}

interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: "idle",
  error: null,
  caseCreate: { data: "" },
  downloadDoc: ""
};

const newCaseSlice = createSlice({
  name: "newCaseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCaseThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(createCaseThunk.fulfilled, (state, action: any) => {
        if (action.payload?.response?.data?.status == 200) toast.success(action.payload?.response?.data?.message);
        else toast.error(action.payload?.response?.data?.message);
        state.loading = "fulfilled";
        state.caseCreate = action.payload;
        state.error = null;
      })
      .addCase(createCaseThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(downloadLeadDocThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(downloadLeadDocThunk.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.downloadDoc = action.payload;
        state.error = null;
        toast.success("Doc Downloaded successfully");
      })
      .addCase(downloadLeadDocThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export default newCaseSlice.reducer;
