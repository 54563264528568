import React, { FC, useEffect, useState } from "react";
import Modal from "../../../../component/common/Modal";
import Typography from "../../../../component/semantic/Typography";
import CloseIcon from "../../../../assets/icons/gallery/close.svg";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux_toolkit";
import { assignToExecutiveThunk, getAllAgentsThunk } from "../../../../store/thunk/AllLead.thunk";
import ReactSelect from "react-select";
import { polyfixSelect } from "../../../../helpers/constants/styles";
import Divide from "../../../../component/semantic/Divide";
import ButtonOutline from "../../../../component/semantic/Button";
import { toast } from "react-toastify";

interface AssignLeadProps {
  showModal: boolean;
  setShowLeadModal: any;
  leadData: any;
}

const AssignLead: FC<AssignLeadProps> = ({ showModal, setShowLeadModal, leadData }) => {
  const agentData = useAppSelector((state) => state.rootReducer.allLead.agentData.data);
  const [executiveData, setExecutiveData] = useState<any>("");

  const [selectedExecutive, setSelectedExecutive] = useState<any>("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllAgentsThunk());
  }, []);

  useEffect(() => {
    if (agentData) {
      const temp: any = [];
      agentData.map((obj: any) => {
        if (obj?.expert === "False" && obj?.channelType === "PARTNER") temp.push(obj);
      });
      setExecutiveData(temp);
    }
  }, [agentData]);

  useEffect(() => {
    if (leadData?.assign_to) setSelectedExecutive({ name: leadData?.assign_to, user_id: leadData?.assign_to });
    return () => { setSelectedExecutive("") };
  }, [leadData]);

  const submitHandler = () => {
    if (selectedExecutive && leadData) {
      dispatch(assignToExecutiveThunk({ leadId: leadData?.leadId?._id, userId: selectedExecutive?.user_id }))
        .unwrap()
        .then((data: any) => {
          if (data?.data?.success) {
            toast.success("Lead Assigned Successfully");
            setShowLeadModal(false);
          }
        });
    }
  };

  return (
    <Modal showModal={showModal} onClose={() => setShowLeadModal(false)} className="relative w-[40%] p-8 pb-4">
      <img src={CloseIcon} className="absolute text-3xl text-gray-400 -right-3 -top-3 cursor-pointer" onClick={() => setShowLeadModal(false)} />
      <p className="text-2xl font-bold">Assign Lead To</p>
      <p className="font-extrabold text-lg my-8">Assign This Lead {leadData?.leadId?.leadId} to:</p>

      <div>
        <Typography className="text-gray-800 mb-2">Assign To *</Typography>
        <ReactSelect menuPlacement="top" placeholder="Assign To" options={executiveData || []} value={selectedExecutive} onChange={(option: any) => setSelectedExecutive(option)} getOptionLabel={(option: any) => option?.name + " (" + option?.user_id + ")"} getOptionValue={(option: any) => option?.user_id} styles={polyfixSelect} />
      </div>
      <Divide className="mb-5 mt-10 -mx-8 shadow-above-border" />
      <div className="flex flex-row-reverse">
        <ButtonOutline title="Assign Executive to Lead" onClick={submitHandler} className={`bg-polyfixTheme w-full md:w-42 mb-2 ${!selectedExecutive && "opacity-50 pointer-events-none"}`} />
      </div>
    </Modal>
  );
};

export default AssignLead;
