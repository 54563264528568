import React, { useEffect, useState } from "react";
import Typography from "../../../component/semantic/Typography";
import SubcategoryTab from "../../../component/common/SubcategoryTab";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { getGalleryContentThunk } from "../../../store/thunk/Gallery.thunk";
import playIcon from "../../../assets/icons/gallery/play.svg";
import download from "../../../assets/icons/gallery/download.svg";
import ReelModal from "./Dialogs/ReelModal";
import view from "../../../assets/icons/gallery/view.svg";
import ReelsSkeleton from "./Skeletons/ReelsSkeleton";
import BlogSkeleton from "./Skeletons/BlogSkeleton";

const InfoCreatives = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showAction, setShowAction] = useState(-1);
  const [playReel, setPlayReel] = useState(false);
  const [selectedReel, setSelectedReel] = useState<any>();

  const galleryData = useAppSelector((state) => state.rootReducer.gallery);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (activeTab == 1 && !galleryData.galleryReels.data) dispatch(getGalleryContentThunk({ category: "Informative Creatives", subcategory: "Reels" }));
    else if (activeTab == 2 && !galleryData.galleryBlogs.data) dispatch(getGalleryContentThunk({ category: "Informative Creatives", subcategory: "Blogs" }));
  }, [activeTab]);

  const handleDownload = (obj: any) => {
    const link = document.createElement("a");
    link.href = obj?.mediaUrl;
    link.setAttribute("download", obj.title + ".mp4");
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const tabs = ["Reels", "Blogs"];
  return (
    <div>
      <div className="flex justify-center">
        <SubcategoryTab activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
      </div>
      {activeTab == 1 ? (
        <div className="grid grid-col-1 md:grid-cols-2 xl:grid-cols-3 1.5xl:grid-cols-4 mt-8 gap-3">
          {galleryData.loading ? (
            <ReelsSkeleton />
          ) : (
            galleryData.galleryReels.data &&
            galleryData.galleryReels.data.map((obj: any, i: any) => {
              return (
                <div key={i} className={`w-full md:w-64 shadow-xl relative rounded-xl overflow-hidden border-2 ${showAction && ""}`} onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
                  <img src={obj?.thumbnailUrl} className="w-full " />
                  <p id="title" className="text-center p-3 font-bold">
                    {obj.title}
                  </p>
                  <div id="overlay" className={`absolute transition-all w-full h-[87%] top-0  justify-center items-center brightness-100 backdrop-brightness-50 ${showAction !== i && "hidden"}`}>
                    <div className="flex gap-4 absolute items-center top-[40%] left-[25%]">
                      <div className="flex gap-3 items-start">
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img
                            src={playIcon}
                            onClick={() => {
                              setSelectedReel(obj.mediaUrl);
                              setPlayReel(true);
                            }}
                          />
                          <Typography className="text-white">Play</Typography>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img src={download} className="filter " onClick={() => handleDownload(obj)} />
                          <Typography className="text-white">Download</Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      ) : (
        <div className="grid grid-col-1 md:grid-cols-2 xl:grid-cols-3 [@media(min-width:1450px)]:grid-cols-5 mt-8 gap-8">
          {galleryData.loading ? (
            <BlogSkeleton />
          ) : (
            galleryData.galleryBlogs.data &&
            galleryData.galleryBlogs.data.map((obj: any, i: any) => {
              return (
                <div key={i} className="w-80 xl:w-72 relative shadow-xl rounded-xl overflow-hidden border-0 " onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
                  <img src={obj?.thumbnailUrl} className="w-full " />

                  <div className={`absolute transition-all w-full h-full top-0  brightness-100 backdrop-brightness-50 ${showAction !== i && "hidden"}`}>
                    <div className="flex gap-4 absolute items-center justify-center h-full w-full">
                      <div className="flex gap-3 items-start">
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img
                            src={view}
                            onClick={() => {
                              window.open(obj.mediaUrl, "_blank");
                            }}
                          />
                          <Typography className="text-white">View</Typography>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                          <img src={download} className="filter " onClick={() => handleDownload(obj)} />
                          <Typography className="text-white">Download</Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
      {playReel && <ReelModal setShowReel={setPlayReel} showReel={playReel} videoSrc={selectedReel} />}{" "}
    </div>
  );
};

export default InfoCreatives;
