import { createSlice } from "@reduxjs/toolkit";
import { addNewCompany, allCompanyList, complaintListThunk, getStateAndDistrict, getUserDropdownThunk, notificationBell, policyListThunk, updateCompany, userListAllThunk, userListAllThunkv2, userProfile } from "../thunk/Common.thunk";
import { toast } from "react-toastify";

interface ApiState {
  data: any;
  loading: "idle" | "pending" | "fulfilled" | "rejected";
  error: string | null;
  companyList: responseData;
  stateList: { name: string }[];
  stateDistrict: responseData;
  userProfile: responseData;
  notificationBell: responseData;
  userListAll: responseData;
  showToast: object;
  inputFieldName: string;
  inputFieldMessage: string;
  allOnlinceUserList: any;
  policyList: responseData;
  complaintList: responseData;
  partnerLogo: string;
  userDropdownList: responseData;
}
export interface responseData {
  data: any;
}

const initialState: ApiState = {
  data: null,
  loading: "idle",
  error: null,
  companyList: { data: "" },
  stateList: [],
  stateDistrict: { data: [] },
  userProfile: { data: "" },
  notificationBell: { data: "" },
  partnerLogo: "",
  userListAll: { data: "" },
  showToast: {},
  inputFieldMessage: "",
  inputFieldName: "",
  allOnlinceUserList: [],
  policyList: { data: "" },
  complaintList: { data: [] },
  userDropdownList: { data: "" }
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.showToast = { show: true, message: action.payload.message, method: action.payload.method };
    },
    hideToast: (state) => {
      state.showToast = { show: false, message: "", method: "" };
    },

    showInputMessage: (state, action) => {
      state.inputFieldName = action.payload.name || "";
      state.inputFieldMessage = action.payload.inputFieldMessage || "This field is required !";
    },
    hideInputMessage: (state) => {
      state.inputFieldName = "";
      state.inputFieldMessage = "";
    },
    setOnlineUser: (state, action) => {
      state.allOnlinceUserList = action.payload;
    },
    updatePartnerLogo: (state, action) => {
      state.partnerLogo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(allCompanyList.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(allCompanyList.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.companyList = action.payload;
        state.error = null;
      })
      .addCase(allCompanyList.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(getStateAndDistrict.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getStateAndDistrict.fulfilled, (state, action) => {
        state.stateList = [];
        if (action.payload?.data) {
          action.payload.data.map((obj: any) => {
            state.stateList.push({ name: obj?.name });
          });
        }

        state.loading = "fulfilled";
        state.stateDistrict = action.payload;
        state.error = null;
      })
      .addCase(getStateAndDistrict.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(userProfile.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(userProfile.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.partnerLogo = action.payload?.data?.partnerLogo;
        state.userProfile = action.payload;
        state.error = null;
      })
      .addCase(userProfile.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(notificationBell.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(notificationBell.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.notificationBell = action.payload;
        state.error = null;
      })
      .addCase(notificationBell.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(userListAllThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(userListAllThunk.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.userListAll = action.payload;
        state.error = null;
      })
      .addCase(userListAllThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(policyListThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(policyListThunk.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.policyList = action.payload;
        state.error = null;
      })
      .addCase(policyListThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(userListAllThunkv2.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(userListAllThunkv2.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.userListAll = action.payload;
        state.error = null;
      })
      .addCase(userListAllThunkv2.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })

      .addCase(complaintListThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(complaintListThunk.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.complaintList = action.payload;
        state.error = null;
      })
      .addCase(complaintListThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(addNewCompany.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(addNewCompany.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.complaintList = action.payload;
        if (action.payload?.success) toast.success(action.payload?.message);
        state.error = null;
      })
      .addCase(addNewCompany.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(updateCompany.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.complaintList = action.payload;
        if (action.payload?.success) toast.success(action.payload?.message);
        state.error = null;
      })
      .addCase(updateCompany.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      })
      .addCase(getUserDropdownThunk.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(getUserDropdownThunk.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.userDropdownList = action.payload;
        state.error = null;
      })
      .addCase(getUserDropdownThunk.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message ?? "An error occurred";
      });
  }
});

export const { showToast, hideToast, showInputMessage, hideInputMessage, setOnlineUser, updatePartnerLogo } = commonSlice.actions;

export default commonSlice.reducer;
