import React, { useEffect, useState, KeyboardEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux_toolkit";
import ActionTable from "./components/ActionTable";
import { initUserHeading, initUserKeys } from "../../component/table/TableConstants";
import Container from "../../component/common/Container";
import Spinner from "../../component/common/Spinner";
import Pagination from "../../component/common/Pagination";
import { actionModalTypes } from "../../helpers/constants/interfaceTypes";
import actionIcon from "../../assets/icons/basic/ActionIcon.svg";
import approveIcon from "../../assets/icons/basic/approve.svg";
import rejectIcon from "../../assets/icons/basic/reject.svg";
import Typography from "../../component/semantic/Typography";
import { getInitUserThunk, deactivateInitUserThunk, approveUserThunk, activateUser, softDelete } from "../../store/thunk/initUser.thunk";
import EditDetails from "./components/EditDetails";
import ConfirmationModal from "./components/ConfirmationModal";
import { toast } from "react-toastify";
import CustomInput from "../../component/semantic/Input";
import ButtonOutline from "../../component/semantic/Button";
import InputIcon from "../../component/semantic/InputIconMob";

const AppInitialUser = () => {
  const initUserData = useAppSelector((state) => state.rootReducer.InitUsers);
  const [selectedRow, setSelectedUser] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [approveConfirmationModal, setApproveConfirmationModal] = useState(false);
  const [rejectConfirmationModal, setRejectConfirmationModal] = useState(false);
  const [confirmationModalText, setConfirmationModalText] = useState("Are you sure?");
  const [toggleApiCall, setToggleApiCall] = useState(false);
  const [userInput, setUserInput] = useState<string>("");

  const pageSize = 20;

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (userInput === "") {
      dispatch(getInitUserThunk({ page: currentPage, size: pageSize, search: "" }));
    }
  }, [currentPage, toggleApiCall]);

  const deactivateInitUser = () => {
    try {
      const payloadData = {
        mobile: selectedRow.mobile
      };
      dispatch(deactivateInitUserThunk(payloadData))
        .unwrap()
        .then(() => {
          setToggleApiCall(!toggleApiCall);
        })
        .catch((error: any) => {
          toast.error(error.message ? error.message : "Something went wrong");
        });
      setRejectConfirmationModal(false);
      if (userInput != "") searchUserHandler();
      else getAllUserHandler();
    } catch (error) {
      console.log(error);
    }
  };

  const approveInitUser = () => {
    try {
      dispatch(approveUserThunk({ ...selectedRow, referral: selectedRow.referral ? selectedRow.referral : "INSA_PARTNER", referred: selectedRow.referred ? selectedRow.referred : true, address: selectedRow?.address?.fullAddress || "", appUser: true, hashPassword: selectedRow?.password || "" }))
        .unwrap()
        .then(() => {
          dispatch(activateUser({ mobile: selectedRow?.mobile }))
            .unwrap()
            .then((data: any) => {
              if (data?.success) {
                dispatch(softDelete({ mobile: selectedRow?.mobile }))
                  .unwrap()
                  .then((data: any) => {
                    if (data?.success) {
                      toast.success("Approved Successfully");
                      setToggleApiCall(!toggleApiCall);
                    }
                  });
              } else {
                // toast.error("Something went wrong");
              }
            });
        })
        // eslint-disable-next-line
        .catch((error: any) => {
          // toast.error(error.message ? error.message : "Something went wrong");
        });
      setApproveConfirmationModal(false);

      if (userInput != "") searchUserHandler();
      else getAllUserHandler();
    } catch (error) {
      console.log(error);
    }
  };

  const actions: actionModalTypes = [
    {
      image: <img src={approveIcon} className="cursor-pointer" />,
      tooltip: "Approve User",
      onClickFunction: () => {
        setConfirmationModalText("Are you sure you want to Approve this user?");
        setApproveConfirmationModal(true);
      }
    },
    {
      image: <img src={rejectIcon} className="cursor-pointer" />,
      tooltip: "Reject User",
      onClickFunction: () => {
        setConfirmationModalText("Are you sure you want to Reject this user?");
        setRejectConfirmationModal(true);
      }
    }
  ];

  const actionTwo: actionModalTypes = [
    {
      image: <img src={actionIcon} className="cursor-pointer w-6 h-6" />,
      tooltip: "Complete Profile",
      onClickFunction: () => setOpenActionModal(true)
    }
  ];

  const searchUserHandler = () => {
    dispatch(getInitUserThunk({ page: 1, size: pageSize, search: userInput?.trim() }));
  };

  const getAllUserHandler = () => {
    setUserInput("");
    dispatch(getInitUserThunk({ page: currentPage, size: pageSize, search: "" }));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      searchUserHandler();
    }
  };

  return (
    <>
      <Container>
        <div className="hidden md:flex justify-center items-end gap-2 w-[60%]">
          <CustomInput parentClassName="w-full flex gap-1 flex-col item-center" onKeyDown={handleKeyDown} onChange={(e) => setUserInput(e.target.value)} value={userInput} placeholder="Name/Mobile/Email" />
          <ButtonOutline type="button" className={`bg-polyfixTheme  w-32 mb-2`} title="Search" onClick={searchUserHandler} />
          <ButtonOutline title="Clear Filter" color="polyfixTheme" className="hover:bg-polyfixTheme w-32 hover:text-white mb-2" onClick={getAllUserHandler} />
        </div>
        <div className="flex md:hidden w-full flex-col gap-1">
          <Typography className="text-polyfixTheme text-end" onClick={getAllUserHandler}>
            Clear All
          </Typography>
          <InputIcon placeholder="Name/Mobile/Email" onClickFunction={searchUserHandler} value={userInput} onChange={(e) => setUserInput(e.target.value)} />
        </div>
      </Container>
      <Container customClassName="mt-6">
        <Typography variant="h1">App Initial Users</Typography>
        <Typography className="text-gray-600 mb-6">List of users registered directly from Polifyx Partner App</Typography>
        {initUserData.loading ? <Spinner /> : <ActionTable actions={actions} actionTwo={actionTwo} selectedRow={setSelectedUser} tableData={initUserData.usersList?.data?.allInitUser} tableHeading={initUserHeading} tableKeys={initUserKeys} />}
        <span className="my-2">&nbsp;</span>
        {initUserData?.usersList?.data?.allInitUser && <Pagination currentPage={currentPage} itemsPerPage={pageSize} setCurrentPage={setCurrentPage} totalItems={initUserData?.usersList?.data?.count} />}
        <EditDetails showModal={openActionModal} setOpenActionModal={setOpenActionModal} data={selectedRow} toggleApiCall={toggleApiCall} setToggleApiCall={setToggleApiCall} />
        <ConfirmationModal confirmationModal={approveConfirmationModal} setConfirmationModal={setApproveConfirmationModal} successButtonHandler={approveInitUser} text={confirmationModalText} />
        <ConfirmationModal confirmationModal={rejectConfirmationModal} setConfirmationModal={setRejectConfirmationModal} successButtonHandler={deactivateInitUser} text={confirmationModalText} />
      </Container>
    </>
  );
};

export default AppInitialUser;
