import React, { useEffect, useState } from "react";
import Typography from "../../../component/semantic/Typography";
import { useFormik } from "formik";
import FormInput from "../../../component/semantic/FormInput";
import ButtonOutline from "../../../component/semantic/Button";
import { useNavigate, useParams } from "react-router-dom";
import Wrapper from "./Wrapper";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { allCompanyList, getStateAndDistrict } from "../../../store/thunk/Common.thunk";
import { natureOfBusiness } from "../../../store/thunk/Profile.thunk";
import ReactSelect from "react-select";
import { Option, MultiValueLabel } from "../../dashboard/components/ReactSelectCustom";
import { polyfixSelect, customMultiValue, errorStyles } from "../../../helpers/constants/styles";
import FormTextArea from "../../../component/semantic/FormTextArea";
import { getExecutiveList, referralViaThunk, signUpThunk } from "../../../store/thunk/Login.thunk";
import { signupSchema } from "../../../helpers/schemas/SignupSchema";
import { toast } from "react-toastify";

const initialValues: any = {
  name: "",
  email: "",
  password: "",
  mobile: "",
  companyName: [],
  otherCompanyName: "",
  website: "",
  natureOfBusiness: "",
  areaOfOperation: [],
  address: "",
  stateName: "",
  districtName: "",
  pincode: "",
  referral: "",
  referralVia: "",
  otherReferralVia: "",
  yearsOfExperience: ""
};

const Signup = () => {
  const [selectedState, setSelectedState] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [districtValues, setDistrictValues] = useState<any>();

  const navigate = useNavigate();
  const params = useParams();

  const dispatch = useAppDispatch();

  const { values, handleSubmit, handleBlur, handleChange, touched, errors, resetForm, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: signupSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      if (values.referralVia === "Others" && values.otherReferralVia === "" && touched?.otherReferralVia) return;

      const payload = {
        ...values,
        companyName: values.companyName.map((obj: any) => obj?.name),
        natureOfBusiness: values.natureOfBusiness?.name,
        areaOfOperation: values.areaOfOperation.map((obj: any) => obj?.name),
        referred: true
      };
      dispatch(signUpThunk(payload))
        .unwrap()
        .then((data: any) => {
          if (data?.success) {
            toast.success(data?.message);
            resetForm();
          }
        });
    }
  });

  const companyList = useAppSelector((state) => state.rootReducer.common.companyList.data);
  const natureOfBuisnessList = useAppSelector((state) => state.rootReducer.profile.natureOfBuisness.data);
  const allStates = useAppSelector((state) => state.rootReducer.common.stateDistrict.data);
  const referralViaList = useAppSelector((state) => state.rootReducer.login.referralVia.data);
  const executiveList = useAppSelector((state) => state.rootReducer.login.executiveList.data);

  const allStateList = useAppSelector((state) => state.rootReducer.common.stateList);
  const formProps = { values, handleBlur, handleChange, touched, errors, setFieldValue };

  useEffect(() => {
    dispatch(allCompanyList());
    dispatch(getStateAndDistrict());
    dispatch(natureOfBusiness());
    dispatch(referralViaThunk());
    dispatch(getExecutiveList());
    if (params.referralCode) setFieldValue("referral", params.referralCode);
  }, []);

  useEffect(() => {
    const districtsOptions = selectedState?.districts?.map((district: any) => ({
      label: district,
      value: district
    }));
    setDistrictValues(districtsOptions);
  }, [selectedState]);

  return (
    <Wrapper>
      <div className="shadow-xl pb-4 p-5 px-8">
        <Typography variant="h3">SIGNUP</Typography>
        <Typography>Create New Account</Typography>
      </div>
      <div className="mt-4 p-5 space-y-3 px-8">
        <FormInput name="name" label="Name *" placeholder={"Enter Name"} formProps={formProps} />
        <FormInput name="email" label="Email *" placeholder={"Enter email"} formProps={formProps} />
        <FormInput name="password" label="Create Password *" placeholder={"Enter password"} formProps={formProps} />
        <FormInput name="mobile" limit={10} label="Mobile *" placeholder={"Enter mobile"} formProps={formProps} />
        <div>
          <Typography className="font-normal mb-1">Company Name *</Typography>

          <ReactSelect
            components={{
              Option: (props) => <Option {...props} checkColor="white" />,
              MultiValueLabel
            }}
            hideSelectedOptions={false}
            isMulti
            value={values.companyName}
            placeholder="Select Company Name"
            styles={values?.companyName?.length === 0 && touched["companyName"] ? errorStyles : { ...polyfixSelect, ...customMultiValue }}
            closeMenuOnSelect={false}
            onChange={(selectedOptions: any) => {
              if (selectedOptions.some((company: any) => company.name === "Other")) setFieldValue("companyName", [{ name: "Other", id: "other_company" }]);
              else setFieldValue("companyName", selectedOptions);
            }}
            options={values.companyName.length > 0 && values.companyName.some((company: any) => company.name === "Other" && company.id === "other_company") ? [{ name: "Other", id: "other_company" }] : [{ name: "Other", id: "other_company" }, ...companyList]}
            getOptionValue={(option: any) => option?._id}
            getOptionLabel={(option: any) => option?.name}
          />
          {values?.companyName?.length === 0 && touched["companyName"] && <span className=" text-red-600 font-semibold text-sm mt-1">Company name is required!</span>}
        </div>
        {values.companyName.length > 0 && values.companyName[0]?.name == "Other" && <FormInput name="otherCompanyName" formProps={formProps} label="Other Company Name*" placeholder="Enter other company name" />}
        <FormInput name="website" placeholder="Enter website url" label="Website URL" formProps={formProps} />
        <div>
          <Typography className="font-normal mb-1">Nature of business *</Typography>

          <ReactSelect
            placeholder="Select Nature of business"
            styles={errors.natureOfBusiness && touched["natureOfBusiness"] ? errorStyles : { ...polyfixSelect, ...customMultiValue }}
            options={natureOfBuisnessList.map((obj: any) => {
              return { name: obj };
            })}
            onChange={(selectedOptions: any) => {
              setFieldValue("natureOfBusiness", selectedOptions);
            }}
            getOptionValue={(option: any) => option?.name}
            getOptionLabel={(option: any) => option?.name}
          />
          {errors.natureOfBusiness && touched["natureOfBusiness"] && <span className=" text-red-600 font-semibold text-sm mt-1">Please Select Nature of business</span>}
        </div>{" "}
        <div>
          <Typography className="font-normal">Area of Operations *</Typography>
          <ReactSelect
            options={values.areaOfOperation.length > 0 && values.areaOfOperation.some((area: any) => area.name === "Pan India") ? [{ name: "Pan India" }] : [{ name: "Pan India" }, ...allStateList]}
            isSearchable={false}
            isMulti
            name="areaOfOperation"
            closeMenuOnSelect={false}
            placeholder="Select Area of Operations"
            value={values.areaOfOperation}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.name}
            styles={values.areaOfOperation.length === 0 && touched["areaOfOperation"] ? errorStyles : { ...polyfixSelect, ...customMultiValue }}
            hideSelectedOptions={false}
            components={{
              Option: (props) => <Option {...props} checkColor="white" />,
              MultiValueLabel
            }}
            onChange={(option: any) => {
              if (option.some((area: any) => area.name === "Pan India")) setFieldValue("areaOfOperation", [{ name: "Pan India" }]);
              else setFieldValue("areaOfOperation", option);
            }}
          />
          {values.areaOfOperation.length === 0 && touched["areaOfOperation"] && <span className=" text-red-600 font-semibold text-sm mt-1">Please Select Area of Operation</span>}
        </div>{" "}
        <div className="row-span-3 h-full mt-3">
          <FormTextArea label="Address (as per Aadhaar Card) *" placeholder="Enter Address" name="address" formProps={formProps} />
        </div>{" "}
        <div className="mt-3">
          <Typography className="font-normal">State *</Typography>
          <ReactSelect
            options={allStates}
            isSearchable={true}
            value={selectedState}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.name}
            styles={errors.stateName && touched["stateName"] ? errorStyles : polyfixSelect}
            name="stateName"
            onChange={(data: any) => {
              setSelectedState(data);
              setFieldValue("stateName", data?.name);
            }}
          />
          {errors.stateName && touched["stateName"] && <span className=" text-red-600 font-semibold text-sm mt-1">Please Select State</span>}
        </div>
        <div className="mt-3">
          <Typography className="font-normal">District *</Typography>
          <ReactSelect
            options={districtValues}
            isSearchable={true}
            value={selectedDistrict}
            styles={errors.districtName && touched["districtName"] ? errorStyles : polyfixSelect}
            onChange={(data: any) => {
              setSelectedDistrict(data);
              setFieldValue("districtName", data?.label);
            }}
          />
          {errors.districtName && touched["districtName"] && <span className=" text-red-600 font-semibold text-sm mt-1">Please Select District</span>}
        </div>
        <FormInput name="pincode" placeholder="Enter Pincode" type="number" label="Pin code*" formProps={formProps} limit={6} />
        <FormInput name="yearsOfExperience" type="number" limit={3} formProps={formProps} label="Years of experience in the industry *" placeholder="Enter Your Experience" />
        <FormInput name="referral" disabled={params.referralCode ? true : false} formProps={formProps} label="Referral Code" />
        <div className="mt-3">
          <Typography className="font-normal">Referral Via</Typography>
          <ReactSelect
            name="referralVia"
            options={referralViaList.map((obj: any) => {
              return { name: obj };
            })}
            onChange={(data: any) => {
              // setFieldTouched("otherReferralVia");
              setFieldValue("referralVia", data?.name);
            }}
            menuPlacement="top"
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.name}
          />
        </div>
        {values.referralVia === "Others" ? (
          values.referral === "INSA_PARTNER" ? (
            <div className="mt-3">
              <Typography className="font-normal">Referral Executive</Typography>
              <ReactSelect
                options={executiveList && executiveList.data}
                onChange={(data: any) => {
                  setFieldValue("otherReferralVia", data?._id);
                }}
                name="otherReferralVia"
                menuPlacement="top"
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option._id}
              />
              {values.referralVia === "Others" && values.otherReferralVia === "" && touched?.otherReferralVia && <span className=" text-red-600 font-semibold text-sm mt-1">Please Select Referral Executive</span>}
            </div>
          ) : (
            <FormInput name="otherReferralVia" limit={10} label="Other Referral Via" formProps={formProps} />
          )
        ) : null}
      </div>

      <div className="px-8 shadow-top py-6">
        <div className="flex justify-between">
          <Typography className="text-polyfixTheme cursor-pointer" onClick={() => navigate("/login")}>
            Log in to your account
          </Typography>
          <Typography className="text-polyfixTheme cursor-pointer" onClick={() => navigate("/auth/recoverPassword")}>
            Recover your password
          </Typography>
        </div>
        <div className="w-full mt-3">
          <ButtonOutline title="SUBMIT" onClick={handleSubmit} className="bg-polyfixTheme w-full" />
        </div>
      </div>
    </Wrapper>
  );
};

export default Signup;
