import React, { useEffect, useState } from "react";
import view from "../../../assets/icons/gallery/view.svg";
import download from "../../../assets/icons/gallery/download.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { downloadTemplateThunk, getGalleryContentThunk } from "../../../store/thunk/Gallery.thunk";
import ViewImg from "./Dialogs/ViewImg";
import SendToPartner from "./Dialogs/SendToPartner";
import DefaultSkeleton from "./Skeletons/DefaultSkleton";
import { barCodeGenrator } from "../../../store/thunk/Referral.thunk";
import { referralUrl } from "../../../helpers/constants/defaultUrls";
import Spinner from "../../../component/common/Spinner";
import { PERMISSIONS } from "../../../helpers/constants/constants";
import { getCurrentUser } from "../../../helpers/currentUser";
import downloadIcon from "../../../assets/icons/gallery/downloadIcon.svg"


const ReferralCreative = () => {
  const [viewImgModal, setViewImgModal] = useState(false);
  const [sendToPartnerOpen, setsendToPartnerOpen] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [isLoading, setLoading] = useState("");

  const dispatch = useAppDispatch();

  const referralContent = useAppSelector((state) => state.rootReducer.gallery);
  const partnerLogoUrl: string = useAppSelector((state) => state.rootReducer.common.partnerLogo);

  useEffect(() => {
    if (!referralContent.galleryReferral.data) dispatch(getGalleryContentThunk({ category: "Referral Creatives" }));
  }, []);

  const currentUser = getCurrentUser();

  const userData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);

  useEffect(() => {
    dispatch(barCodeGenrator(referralUrl + userData?.referralCode))
      .unwrap()
      .then((data) => {
        if (data?.success) setReferralCode(data?.data);
      });
  }, []);

  const [showAction, setShowAction] = useState(-1);
  const [selectedImg, setSelectedImg] = useState("");

  const handleDownload = (id: any) => {
    setLoading(id);
    dispatch(downloadTemplateThunk({ id, modify: true }))
      .unwrap()
      .then((data) => {
        if (data) {
          const blob = new Blob([data], { type: "image/png" });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "template.png"; // Specify the filename
          document.body.appendChild(a);
          a.click();
          a.remove();

          // Optional: revoke the URL object to free up memory
          window.URL.revokeObjectURL(url);
          setLoading("");
        }
      });
  };
  return (
    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {referralContent.loading ? (
        <DefaultSkeleton />
      ) : (
        referralContent.galleryReferral.data &&
        referralContent.galleryReferral.data.map((obj: any, i: any) => {
          if (isLoading == obj._id) {
            return (
              <div key={"download" + i} className="h-full bg-gray-300 relative flex justify-center items-center">
                <img src={obj?.mediaUrl} className="opacity-50 w-full h-auto" />
                <div className="absolute inset-0 flex justify-center items-center">
                  <Spinner className="text-3xl" />
                </div>
              </div>
            );
          }
          return (
            <div key={i} className={`w-full border-2   shadow-lg relative rounded-lg ${showAction && ""}`} onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
              {!currentUser?.roles.includes(PERMISSIONS.ADMIN) ? (
                <>
                  <img id="referralCode" src={referralCode} className="absolute bottom-[2%] right-[4%] w-[18%] h-[18%] rounded-lg" />
                  {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[2%] left-[4%] w-[20%] h-[10%]" />}
                </>
              ) : (
                ""
              )}
              <img src={obj?.mediaUrl} className="w-full  rounded-lg" />
              <div className={`absolute transition-all w-full h-full top-0  rounded-lg brightness-100 backdrop-brightness-50 ${showAction !== i && "hidden"}`}>
                <div className="flex gap-4 absolute items-center justify-center h-full w-full">
                  <div className="flex gap-3 items-start ">
                    <div className="flex flex-col w-1/3 justify-center items-center gap-1 cursor-pointer rounded-2xl">
                      <img
                        src={view}
                        onClick={() => {
                          setSelectedImg(obj?.mediaUrl);
                          setViewImgModal(true);
                        } }
                        className="w-6 h-6  md:h-10 md:w-10 	 "
                      />
                      <p className="text-white text-xs lg:text-base">View</p>
                    </div>
                    <div className="flex w-1/3   flex-col justify-center items-center gap-1 cursor-pointer 	">
                      <img src={download} onClick={() => handleDownload(obj._id)} className="w-6 h-6 md:h-10 md:w-10" />
                      <p className="text-white text-sm lg:text-base text-center">Download</p>
                    </div>
                    {currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
                      <div className="flex w-1/3  flex-col justify-center items-center gap-1 cursor-pointer ">
                        <img
                          src={downloadIcon}
                          onClick={() => {
                            setSelectedImg(obj?.mediaUrl);
                            setsendToPartnerOpen(true);
                          }}
                          className="w-6 h-6  md:h-10 md:w-10"
                        />
                        <p className="text-white text-sm lg:text-base text-center">Download For Partners</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}

      <ViewImg setShowImg={setViewImgModal} showImg={viewImgModal} imgSrc={selectedImg} fromReferral={true} />
      <SendToPartner addLogoModal={sendToPartnerOpen} setShowAddLogoModal={setsendToPartnerOpen} imgSrc={selectedImg} />
    </div>
  );
};

export default ReferralCreative;
