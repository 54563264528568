import React, { useEffect, useState } from "react";
import Typography from "../../../component/semantic/Typography";
import view from "../../../assets/icons/gallery/view.svg";
import download from "../../../assets/icons/gallery/download.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux_toolkit";
import { downloadTemplateThunk, getGalleryContentThunk } from "../../../store/thunk/Gallery.thunk";
import ViewImg from "./Dialogs/ViewImg";
import DefaultSkeleton from "./Skeletons/DefaultSkleton";
import { barCodeGenrator } from "../../../store/thunk/Referral.thunk";
import { referralUrl } from "../../../helpers/constants/defaultUrls";
import { userProfile } from "../../../store/thunk/Common.thunk";
import { getCurrentUser } from "../../../helpers/currentUser";
import Spinner from "../../../component/common/Spinner";
import { PERMISSIONS } from "../../../helpers/constants/constants";
import SendToPartner from "./Dialogs/SendToPartner";
import downloadIcon from "../../../assets/icons/gallery/downloadIcon.svg"

const LeadCreative = () => {
  const [viewImgModal, setViewImgModal] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [isLoading, setLoading] = useState("");
  const [sendToPartnerOpen, setsendToPartnerOpen] = useState(false);
  const [showAction, setShowAction] = useState(-1);
  const [selectedImg, setSelectedImg] = useState("");

  const dispatch = useAppDispatch();

  const leadContent = useAppSelector((state) => state.rootReducer.gallery);

  const currentUser = getCurrentUser();
  useEffect(() => {
    if (!leadContent?.galleryLeadGeneration?.data) dispatch(getGalleryContentThunk({ category: "Lead Generation Creatives" }));
    dispatch(userProfile(currentUser._id));
  }, []);

  const userData: any = useAppSelector((state) => state.rootReducer.common.userProfile?.data);
  const partnerLogoUrl: string = useAppSelector((state) => state.rootReducer.common.partnerLogo);

  useEffect(() => {
    dispatch(barCodeGenrator(referralUrl + userData?.referralCode))
      .unwrap()
      .then((data) => {
        if (data?.success) setReferralCode(data?.data);
      });
  }, []);

  const handleDownload = (obj: any) => {
    setLoading(obj._id);
    dispatch(downloadTemplateThunk({ id: obj._id, modify: true }))
      .unwrap()
      .then((data) => {
        if (data) {
          const blob = new Blob([data], { type: "image/png" });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = "template.png"; // Specify the filename
          document.body.appendChild(a);
          a.click();
          a.remove();

          window.URL.revokeObjectURL(url);
          setLoading("");
        }
      });
  };

  return (
    <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {leadContent.loading ? (
        <DefaultSkeleton />
      ) : (
        leadContent.galleryLeadGeneration.data &&
        leadContent.galleryLeadGeneration.data.map((obj: any, i: any) => {
          if (isLoading == obj._id) {
            return (
              <div key={"download" + i} className="h-full bg-gray-300 absolute flex justify-center items-center rounded-lg ">
                <img src={obj?.mediaUrl} className="opacity-50 w-full h-auto rounded-lg" />
                <div className="absolute inset-0 flex justify-center items-center">
                  <Spinner className="text-3xl" />
                </div>
              </div>
            );
          }

          return (
            <div key={i} className={`w-full border-10  shadow-lg rounded-lg relative ${showAction && ""}`} onMouseEnter={() => setShowAction(i)} onMouseLeave={() => setShowAction(-1)}>
              <img id="main-image" src={obj?.mediaUrl} className="w-full rounded-lg" />
              {!currentUser?.roles.includes(PERMISSIONS.ADMIN) ? (
                <>
                  {partnerLogoUrl && <img src={partnerLogoUrl} className="absolute top-[2%] left-[4%] w-[20%] h-[10%]" />}
                  <img id="referralCode" src={referralCode} className="absolute bottom-[2%] right-[4.1%] w-[18%] h-[18%] rounded" />
                </>
              ) : (
                ""
              )}
              <div className={`absolute transition-all w-full h-full top-0 rounded-lg brightness-100 backdrop-brightness-50  ${showAction !== i && "hidden"}`}>
                <div className="flex gap-4 absolute items-center justify-center h-full w-full">
                  <div className="flex gap-3 items-start">
                    <div className="flex flex-col w-1/3 justify-center items-center gap-1 cursor-pointer rounded-2xl">
                      <img
                        src={view}
                        onClick={() => {
                          setSelectedImg(obj?.mediaUrl);
                          setViewImgModal(true);
                        }}
                      />
                      <Typography className="text-white text-xs lg:text-base">View</Typography>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-1 cursor-pointer">
                      <img
                        src={download}
                        className="filter"
                        onClick={() => {
                          handleDownload(obj);
                        }}
                      />
                      <Typography className="text-white">Download</Typography>
                    </div>
                    {currentUser?.roles.includes(PERMISSIONS.ADMIN) && (
                      <div className="flex w-1/3 flex-col justify-center items-center gap-1 cursor-pointer">
                        <img
                          src={downloadIcon}
                          onClick={() => {
                            setSelectedImg(obj?.mediaUrl);
                            setsendToPartnerOpen(true);
                          }}
                          className="w-6 h-6 md:h-10 md:w-10"
                        />
                        <p className="text-white text-sm lg:text-base text-center">Download For Partners</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}

      <ViewImg setShowImg={setViewImgModal} showImg={viewImgModal} imgSrc={selectedImg} fromReferral={true} />
      <SendToPartner addLogoModal={sendToPartnerOpen} setShowAddLogoModal={setsendToPartnerOpen} imgSrc={selectedImg} />
    </div>
  );
};

export default LeadCreative;
